import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePath } from "../../enums/RoutePath";
import { StaticContent } from "../../enums/StaticContent";
import { useAppSelector } from "../../store/hooks";
import { useGetStaticContentQuery } from "../../store/services/loanNote";
import { selectCanAccessProduct } from "../../store/status/statusSlice";
import SkeletonWrapper from "../SkeletonWrapper";

type SeriesNotAvailableProps = {
  redirect?: boolean;
  children?: React.ReactNode;
};

export default function SeriesNotAvailable({
  redirect = false,
  children,
  ...props
}: SeriesNotAvailableProps) {
  const { data: staticContent, isFetching } = useGetStaticContentQuery();
  const canAccessProduct = useAppSelector(selectCanAccessProduct);

  if (redirect && !canAccessProduct)
    return <Redirect to={RoutePath.NotAvailable} />;

  return !canAccessProduct ? (
    <Dialog
      open
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 1500,
      }}
      PaperProps={{
        sx: {
          alignItems: "start",
        },
      }}
      {...props}
    >
      <SkeletonWrapper
        isLoading={isFetching}
        alternateComponent={
          <DialogTitle id="alert-dialog-title" color="primary">
            Product not available
          </DialogTitle>
        }
        sx={{
          mx: 3,
        }}
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          {staticContent?.[StaticContent.SeriesNotAvailablePopupTitle]}
        </DialogTitle>
      </SkeletonWrapper>
      <DialogContent
        sx={{
          pt: 0,
        }}
      >
        {!children ? (
          <SkeletonWrapper
            isLoading={isFetching}
            alternateComponent={
              <DialogContentText id="alert-dialog-description">
                Cannot go forward with the application.
              </DialogContentText>
            }
            width="inherit"
          >
            <DialogContentText id="alert-dialog-description">
              {staticContent?.[StaticContent.SeriesNotAvailablePopupBody]}
            </DialogContentText>
          </SkeletonWrapper>
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  ) : null;
}
