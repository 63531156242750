import { Container, Typography } from "@mui/material";
import React from "react";
import { StaticContent } from "../enums/StaticContent";
import { useGetStaticContentQuery } from "../store/services/loanNote";

export default function NotFound() {
  const { data: staticContent } = useGetStaticContentQuery();
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography variant="h2" align="center" color="primary">
        {staticContent?.[StaticContent.NotFoundPageMessage]}
      </Typography>
    </Container>
  );
}
