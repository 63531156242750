import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RestCountriesApiPath } from "../../enums/RestCountriesApiPath";
import { uniqueBy } from "../../functions";
import { Country, Currency } from "../../types/Country";

export const restCountriesApi = createApi({
  reducerPath: "restCountriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_REST_COUNTRIES_API,
  }),
  endpoints: (builder) => ({
    getAllCountries: builder.query<Array<string>, void>({
      query: () => `${RestCountriesApiPath.All}?fields=name`,
      transformResponse: (response: Country[]) =>
        response
          .map((country: Country) => country?.name?.common ?? "")
          .filter((country) => country)
          .sort(),
    }),
    getAllNationalities: builder.query<Array<string>, void>({
      query: () => `${RestCountriesApiPath.All}?fields=demonyms`,
      transformResponse: (response: Country[]) =>
        Array.from(
          new Set(
            response
              .map((country: Country) => country?.demonyms?.eng?.f ?? "")
              .filter((nationality) => nationality)
          )
        ).sort(),
    }),
    getAllCurrencies: builder.query<Array<string>, void>({
      query: () => `${RestCountriesApiPath.All}?fields=currencies`,
      transformResponse: (response: Country[]) =>
        Array.from(
          new Set([
            ...response
              .map((country) => Object.keys(country.currencies))
              .flat(),
          ])
        ).sort(),
    }),
    getAllCurrenciesDetails: builder.query<Array<Currency>, void>({
      query: () => `${RestCountriesApiPath.All}?fields=currencies`,
      transformResponse: (response: Country[]) =>
        uniqueBy(
          response
            .map((country) =>
              Object.entries(country.currencies).map(([iso3, currencyObj]) => ({
                ...currencyObj,
                iso3,
              }))
            )
            .filter((currencies) => currencies.length !== 0)
            .flat(),
          (value: Currency) => value.name
        ).sort((a, b) => a.name.localeCompare(b.name)),
    }),
  }),
});

export const {
  useGetAllCountriesQuery,
  useGetAllNationalitiesQuery,
  useGetAllCurrenciesQuery,
  useGetAllCurrenciesDetailsQuery,
} = restCountriesApi;
