import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonType } from "../../enums/PersonType";
import { RootState } from "../store";
import countries from "../../json/countries.json";
import nationalities from "../../json/nationalities.json";
import currencies from "../../json/currencies.json";
import { Currency } from "../../types/Country";

export type StatusState = {
  ISIN: string;
  apiKey: string;
  personType: PersonType | string;
  canAccessProduct: boolean;
  countries: Array<string>;
  nationalities: Array<string>;
  currencies: Array<string>;
  currenciesDetails: Array<Currency>;
};

// Define the initial state using that type
const initialState: StatusState = {
  ISIN: "",
  apiKey: "9ezMkO3EdO5vwYwUSRhNQSjswLZ3ssdFEwpIJOLF",
  personType: "",
  canAccessProduct: false,
  countries: countries.map((country) => country.name),
  nationalities: nationalities,
  currencies: Object.keys(currencies).sort(),
  currenciesDetails: Object.values(currencies)
    .map(({ code, name, symbol }): Currency => ({ iso3: code, name, symbol }))
    .sort((a, b) => a.name.localeCompare(b.name)),
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setISIN: (state, action: PayloadAction<string>) => {
      state.ISIN = action.payload;
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setPersonType: (state, action: PayloadAction<string>) => {
      state.personType = action.payload;
    },
    setCanAccessProduct: (state, action: PayloadAction<boolean>) => {
      state.canAccessProduct = action.payload;
    },
    allowProductAccess: (state) => {
      state.canAccessProduct = true;
    },
    disallowProductAccess: (state) => {
      state.canAccessProduct = false;
    },
  },
});

export const {
  setISIN,
  setApiKey,
  setPersonType,
  setCanAccessProduct,
  allowProductAccess,
  disallowProductAccess,
} = statusSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectISIN = (state: RootState) => state.status.ISIN;
export const selectApiKey = (state: RootState) => state.status.apiKey;
export const selectPersonType = (state: RootState) => state.status.personType;
export const selectCanAccessProduct = (state: RootState) =>
  state.status.canAccessProduct;
export const selectCountries = (state: RootState) => state.status.countries;
export const selectNationalities = (state: RootState) =>
  state.status.nationalities;
export const selectCurrencies = (state: RootState) => state.status.currencies;
export const selectCurrenciesDetails = (state: RootState) =>
  state.status.currenciesDetails;

export default statusSlice.reducer;
