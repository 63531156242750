import * as yup from "yup";
import { BackendError } from "./types/BackendError";
import { MappedError } from "./types/MappedError";
import { Option } from "./types/Option";

export function getKeyName(object: Object, value: string) {
  return Object.entries(object).find(([key, val]) => val === value)?.[0];
}

export function addNamespace(namespace = "", separator = "_") {
  return function withNamespace(fieldName: string = "") {
    return namespace ? namespace + separator + fieldName : fieldName;
  };
}

export const flatten = (obj: any) => {
  const flattened: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flatten(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

// Flattens an object.
export function* flattenObject(obj: Object, flattenArray = false): any {
  // Loop each key -> value pair entry in the provided object.
  for (const [key, value] of Object.entries(obj)) {
    // If the target value is an object and it's not null (because typeof null is 'object'), procede.
    if (typeof value === "object" && value !== null) {
      // if the targeted value is an array and arrays should be flattened, flatten the array.
      if (Array.isArray(value) && flattenArray) yield* flattenObject(value);
      // Otherwise, if the value is not an array, flatten it (it must be an object-like or object type).
      else if (!Array.isArray(value)) yield* flattenObject(value);
      // otherwise, just yield the key->value pair.
      else yield [key, value];
    }
    // otherwise, the value must be something which is not an object, hence, just yield it.
    else yield [key, value];
  }
}

export const camelToSnakeCase = (str: string) => {
  const matches = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  );
  if (!matches) return str;
  return matches.map((x) => x.toLowerCase()).join("_");
};

export function snakeToCamelCase(str: string) {
  return str.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
}

export function keysToCase(value: any, caseConverter?: Function): any {
  if (!caseConverter) return value;
  if (isRegularObject(value)) {
    const newObj: any = {};

    Object.keys(value).forEach((key: string) => {
      newObj[caseConverter(key)] = keysToCase(value[key], caseConverter);
    });

    return newObj;
  }
  if (Array.isArray(value)) {
    return value.map((element: any) => {
      return keysToCase(element);
    });
  }

  return value;
}

export function isArray(value: any): boolean {
  return Array.isArray(value);
}

export function isRegularObject(value: any): boolean {
  return (
    isObject(value) && !Array.isArray(value) && typeof value !== "function"
  );
}

export function createYupSchema(schema: any, config: any) {
  const { id, validationType, validations = [] } = config;
  if (!(yup as any)[validationType]) {
    return schema;
  }
  let validator = (yup as any)[validationType]();
  validations.forEach((validation: any) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[id] = validator;
  return schema;
}

export function namespaceKeys(object: Object, namespace = "", separator = "_") {
  const withNamespace = addNamespace(namespace, separator);
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [withNamespace(key), value])
  );
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date?.valueOf());
}

export function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

export function isEmptyObject(obj: Object): boolean {
  return Object.keys(obj)?.length === 0;
}

export const titleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

export const sentenceCase = (s: string) =>
  // eslint-disable-next-line no-useless-escape
  s.replace(/\.\s+([a-z])[^\.]|^(\s*[a-z])[^\.]/g, (s) =>
    s.replace(/([a-z])/, (s) => s.toUpperCase())
  );

export function isTrue(value: any) {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

export function uniqueBy<T>(array: T[], cb: Function): T[] {
  const unique: Record<string, boolean> = {};
  const distinct: T[] = [];
  array.forEach(function (item) {
    let key = cb(item);
    if (!unique[key]) {
      distinct.push(item);
      unique[key] = true;
    }
  });
  return distinct;
}

export function getSelectedOption(options: any, cb: Function) {
  return options.find(cb);
}

export function isPopupBlocked(popupWindow: Window | null) {
  if (popupWindow == null) return true;
  if (popupWindow?.closed) return true;

  return false;
}

export function isInIframe() {
  return window.self !== window.top;
}

export function mapErrorsFromRequest(error: any): any {
  if (!isObject(error)) {
    return error;
  }

  if (Array.isArray(error)) {
    return error.map(mapErrorsFromRequest);
  }

  const mappedErrors: MappedError = {};

  for (const [key, value] of Object.entries(error)) {
    if (Array.isArray(value)) {
      mappedErrors[key] = mapErrorsFromRequest(value);
    } else {
      let error = value as BackendError;
      const mappedErrorValue =
        (error._required ||
          error._invalid ||
          error._empty ||
          error.length ||
          error.size ||
          error.numeric) ??
        "";
      mappedErrors[key] = mappedErrorValue;
    }
  }

  return mappedErrors;
}

export function transformValuesByKey(
  values: Object | Array<Object>,
  key: string,
  transformCb: (value: any) => any
): Object | Array<Object> {
  if (isRegularObject(values)) {
    return Object.fromEntries(
      Object.entries(values).map(([objectKey, objectValue]) => {
        if (objectKey.includes(key)) {
          return [objectKey, transformCb(objectValue)];
        }
        return [objectKey, transformValuesByKey(objectValue, key, transformCb)];
      })
    );
  }

  if (Array.isArray(values)) {
    return (values as Array<Object>).map((value) =>
      transformValuesByKey(value, key, transformCb)
    );
  }

  return values;
}

export function convertObjectToJSON(
  values: any,
  startAtDepth = 1,
  depth = 1
): any {
  const stopRecursion = depth >= startAtDepth;
  if (isRegularObject(values)) {
    return stopRecursion
      ? JSON.stringify(values)
      : Object.fromEntries(
          Object.entries(values).map(([key, value]) =>
            isObject(values)
              ? [key, convertObjectToJSON(value, startAtDepth, depth + 1)]
              : [key, value]
          )
        );
  }

  if (Array.isArray(values)) {
    return values.map((value: any) =>
      isObject(value)
        ? convertObjectToJSON(value, startAtDepth, depth + 1)
        : value
    );
  }

  return values;
}

export function isFile(value: any): boolean {
  if ("File" in window && value instanceof File) return true;
  return false;
}

export function isBlob(value: any): boolean {
  if ("Blob" in window && value instanceof Blob) return true;
  return false;
}

export function filterArrayFromIndex(
  array: Array<any>,
  index: number
): Array<any> {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
}

export function transformEnumToOptions(value: Record<string, any>): Option[] {
  return Object.entries(value).map(([value, label]) => ({
    label,
    value,
  }));
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export const transformObjectToDotNotation = (
  obj: any,
  prefix = "",
  result: any[] = []
) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (!value) return;
    let nextKey = prefix ? `${prefix}.${key}` : key;
    if (Array.isArray(obj)) {
      nextKey = prefix ? `${prefix}[${key}]` : key;
    }

    if (typeof value === "object") {
      transformObjectToDotNotation(value, nextKey, result);
    } else {
      result.push(nextKey);
    }
  });

  return result;
};

export const getFieldErrorNames = (
  formikErrors: any,
  transformCb = transformObjectToDotNotation
) => {
  return transformCb(formikErrors);
};

export function isStringInAnotherString(
  firstString?: string | null,
  secondString?: string | null
): boolean {
  if (firstString == null || secondString == null) return false;
  return secondString.toLowerCase().includes(firstString.toLowerCase());
}
