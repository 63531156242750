import { FiduciaryFormKeys } from "../../../enums/FiduciaryFormKeys";

export const FIDUCIARY_SERVICES_REQUEST =
  FiduciaryFormKeys.FiduciaryServicesRequest;

export const NAME_OF_ISSUER = {
  name: "issuer_name",
  label: "Name of Issuer",
  requiredErrorMessage: "Name of Issuer is required",
} as const;

export const REGISTRATION_NUMBER_OF_ISSUER = {
  name: "registration_number",
  label: "Registration number of Issuer",
  requiredErrorMessage: "Registration number of Issuer is required",
} as const;

export const NAME_OF_SECURITY = {
  name: "nickname",
  label: "Name of Security",
  requiredErrorMessage: "Name of Security is required",
} as const;

export const ISIN = {
  name: "isin",
  label: "ISIN number",
  requiredErrorMessage: "ISIN number is required",
} as const;

export const SUBSCRIPTION_AMOUNT = {
  name: "subscription_amount",
  label: "Subscription Amount",
  minErrorMessage: "Minimum value is ${min}",
  requiredErrorMessage: "Subscription Amount is required",
} as const;

export const ADVISER_EMAIL = {
  name: "adviser_email",
  label: "Adviser / Broker Email",
  emailErrorMessage: "Email must be valid",
  requiredErrorMessage: "Adviser Email is required",
} as const;
