import React, { Suspense } from "react";
import { lazy } from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { RoutePath } from "./enums/RoutePath";
import Animator from "./Animator";
import theme from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import {
  useGetStaticContentQuerySubscription,
  useGetTypesQuerySubscription,
} from "./store/services/loanNote";
import CustomerClassification from "./routes/CustomerClassification";
import TermsOfConditions from "./routes/terms-of-conditions/TermsOfConditions";
import NotApplicable from "./routes/NotApplicable";
import NotFound from "./routes/NotFound";
import SeriesNotAvailable from "./components/form-components/SeriesNotAvailable";

const FiduciaryApplicationForm = lazy(() =>
  pMinDelay(import("./routes/FiduciaryApplicationForm"), 400)
);

export default function App() {
  const location = useLocation();
  useGetStaticContentQuerySubscription();
  useGetTypesQuerySubscription();
  const AnimatedCustomerClassification = Animator(CustomerClassification);
  const AnimatedTermsOfConditions = Animator(TermsOfConditions);
  const AnimatedNotApplicable = Animator(NotApplicable);
  const AnimatedFiduciaryApplicationForm = Animator(FiduciaryApplicationForm);
  const AnimatedNotFound = Animator(NotFound);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <CssBaseline />
      <TransitionGroup id="content">
        <Switch location={location}>
          <Route path={RoutePath.Root} exact>
            <AnimatedCustomerClassification />
          </Route>
          <Route path={RoutePath.CustomerClassification}>
            <AnimatedCustomerClassification />
          </Route>
          <Route path={RoutePath.NotAvailable}>
            <SeriesNotAvailable />
          </Route>
          <Route path={`${RoutePath.ToC}/:pageNumber`}>
            <SeriesNotAvailable redirect />
            <AnimatedTermsOfConditions />
          </Route>
          <Route path={RoutePath.NotApplicable}>
            <AnimatedNotApplicable />
          </Route>
          <Route path={RoutePath.FiduciaryApplicationForm}>
            <SeriesNotAvailable />
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: "flex",
                    m: "auto",
                  }}
                >
                  <CircularProgress size={70} thickness={1} />
                </Box>
              }
            >
              <AnimatedFiduciaryApplicationForm />
            </Suspense>
          </Route>
          <Route path="*">
            <AnimatedNotFound />
          </Route>
        </Switch>
      </TransitionGroup>
    </ThemeProvider>
  );
}
