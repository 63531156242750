export enum StaticContent {
  LandingPageHeader = "Landing Page Header",
  LandingPageDescription = "Landing Page Description",
  LandingPageIntroduction = "Landing Page Introduction",
  NotApplicablePageHeader = "Not Applicable Page Header",
  NotApplicablePageDescription = "Not Applicable Page Description",
  NotFoundPageMessage = "Not Found Page Message",
  SuccessPopupTitle = "Success Popup Title",
  SuccessPopupBody = "Success Popup Body",
  SuccessPopupEnd = "Success Popup End",
  SuccessPopupEndReferrer = "Success Popup End Referrer",
  SuccessPopupEndReferrerButton = "Success Popup End Referrer Button",
  ErrorAlertTitle = "Error Alert Title",
  SeriesNotAvailablePopupTitle = "Series Not Available Popup Title",
  SeriesNotAvailablePopupBody = "Series Not Available Popup Body",
  SeriesNotAvailableProductMissingNominee = "Series Not Available Product Missing Nominee",
  SeriesMissingInformationPopupTitle = "Series Missing Information Popup Title",
  SeriesMissingInformationPopupBody = "Series Missing Information Popup Body",
}
