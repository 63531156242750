import { createTheme } from "@mui/material/styles";

const theme = {
  success: {
    main: "rgb(7, 102, 51)",
  },
  warning: {
    main: "rgb(174, 141, 74)",
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

declare module "@mui/material/styles" {
  interface Theme extends CustomTheme {
    success: {
      main: React.CSSProperties["color"];
    };
    warning: {
      main: React.CSSProperties["color"];
    };
  }
  interface ThemeOptions {
    success?: {
      main?: string;
    };
    warning?: {
      main?: string;
    };
  }
}

export default createTheme(theme);
