import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import SkeletonWrapper from "../components/SkeletonWrapper";
import { StaticContent } from "../enums/StaticContent";
import { useGetStaticContentQuery } from "../store/services/loanNote";

export default function NotApplicable() {
  const { data: staticContent, isLoading } = useGetStaticContentQuery();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Grid container component="main" justifyContent="center" spacing={1}>
        <Grid item xs={10} md={12}>
          <SkeletonWrapper
            isLoading={isLoading}
            alternateComponent={
              <Typography
                variant="h2"
                component="h1"
                color="primary.main"
                align="center"
              >
                You are not applicable
              </Typography>
            }
          >
            <Typography
              variant="h2"
              component="h1"
              color="primary.main"
              align="center"
              sx={{
                fontVariant: "all-petite-caps",
                wordSpacing: 3,
                textDecoration: "underline 1px",
              }}
              letterSpacing={2}
            >
              {staticContent?.[StaticContent.NotApplicablePageHeader]}
            </Typography>
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={6}>
          <SkeletonWrapper
            isLoading={isLoading}
            alternateComponent={
              <Typography variant="subtitle1" component="p" align="justify">
                Sorry, but we only service financial advisers, high net worth
                individuals, and professionals.
              </Typography>
            }
          >
            <Typography variant="subtitle1" component="p" align="justify">
              {staticContent?.[StaticContent.NotApplicablePageDescription]}
            </Typography>
          </SkeletonWrapper>
        </Grid>
      </Grid>
    </Container>
  );
}
