import { Skeleton, SkeletonProps } from "@mui/material";
import React from "react";

export type SkeletonWrapperProps = {
  isLoading?: boolean;
  alternateComponent: React.ReactElement<any>;
  props?: Object;
};

export default function SkeletonWrapper({
  isLoading = false,
  alternateComponent,
  children,
  ...props
}: SkeletonProps & SkeletonWrapperProps) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          sx={{
            width: "100%",
            mx: "auto !important",
          }}
          {...props}
        >
          {alternateComponent}
        </Skeleton>
      ) : (
        children
      )}
    </>
  );
}
