import { useLayoutEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";

export default function useFontLoad(
  fontType: string,
  fontDescription = {},
  testString?: string | null,
  timeout?: number,
  successCb = (value: any) => null,
  failureCb = (reason: any) => null
) {
  const [hasLoaded, setHasLoaded] = useState(false);

  useLayoutEffect(() => {
    const font = new FontFaceObserver(fontType, fontDescription);
    font
      .load(testString, timeout)
      .then(successCb)
      .catch(failureCb)
      .finally(() => setHasLoaded(true));

    return () => setHasLoaded(false);
  }, [fontType]);

  return hasLoaded;
}
