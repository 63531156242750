import React from "react";
import { Circle } from "@mui/icons-material";
import { Container, ListItem } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { selectPersonType } from "../../store/status/statusSlice";
import { useAppSelector } from "../../store/hooks";
import { PersonType } from "../../enums/PersonType";
import PersonalContent from "./PersonalContent";
import CorporateContent from "./CorporateContent";

export const CustomListItem = styled(ListItem)(({ theme }) => ({
  textAlign: "justify",
  alignItems: "baseline",
  paddingLeft: 0,
  paddingTop: theme.spacing(0.3),
  paddingBottom: theme.spacing(0.3),
}));

export const CustomCircle = styled(Circle)(() => ({
  fontSize: "0.5rem",
}));

export default function ContentPage() {
  const theme = useTheme();
  const personType = useAppSelector(selectPersonType);
  const isCorporate = personType === PersonType.Corporate;

  return (
    <Container
      maxWidth="md"
      component="main"
      sx={{
        minHeight: "100%",
        height: "auto",
        pt: 4,
        pb: 12,
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
          py: 8,
        },
      }}
    >
      {isCorporate ? <CorporateContent /> : <PersonalContent />}
    </Container>
  );
}
