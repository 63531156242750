import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import statusReducer from "./status/statusSlice";
import { loanNoteApi } from "./services/loanNote";
import { restCountriesApi } from "./services/restCountries";

export const store = configureStore({
  reducer: {
    status: statusReducer,
    [loanNoteApi.reducerPath]: loanNoteApi.reducer,
    [restCountriesApi.reducerPath]: restCountriesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loanNoteApi.middleware,
      restCountriesApi.middleware
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
