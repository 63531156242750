export enum FiduciaryFormKeys {
  PersonalDetails = "personal_details",
  ContactDetails = "contact_details",
  FiduciaryServicesRequest = "fiduciary_services_request",
  ServicesDeclaration = "services_declaration",
  BankDetails = "bank_details",
  SourceOfWealthAndFunds = "source_of_wealth_and_funds",
  Source = "source",
  SourceOfWealth = "source_of_wealth",
  SourceOfFunds = "source_of_funds",
  Questionnaires = "questionnaires",
  Questionnaire = "questionnaire",
  Passport = "passport",
  Passports = "passports",
  TaxStatus = "tax_status",
  TaxStatuses = "tax_statuses",
  ServicesDeclarationQuestionnaire = "services_declaration_questionnaire",
  ReputationAndCharacterQuestionnaire = "reputation_and_character",
  ReputationAndCharacter = "reputation_and_character",
  EntityInformation = "entity_information",
  Entity = "entity",
  Shareholder = "shareholder",
  Shareholders = "shareholders",
  Share = "share",
  Shares = "shares",
  IndividualShareholder = "individual_shareholder",
  IndividualShareholders = "individual_shareholders",
  CorporateShareholder = "corporate_shareholder",
  CorporateShareholders = "corporate_shareholders",
  UltimateBeneficialOwner = "ultimate_beneficial_owner",
  UltimateBeneficialOwners = "ultimate_beneficial_owners",
  Director = "director",
  Directors = "directors",
  KeyIndividual = "key_individual",
  KeyIndividuals = "key_individuals",
  EntityDeclarations = "entity_declarations",
  PersonalDeclaration = "personal_declaration",
  CorporateDeclaration = "corporate_declaration",
  Confirmation = "confirmation",
}
