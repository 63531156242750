import { PrefetchOptions } from "@reduxjs/toolkit/dist/query/core/module";
import { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { loanNoteApi } from "./services/loanNote";
import { restCountriesApi } from "./services/restCountries";
import { AppDispatch, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type EndpointNames =
  | keyof typeof loanNoteApi.endpoints
  | keyof typeof restCountriesApi.endpoints;

export function usePrefetchImmediately<T extends EndpointNames>(
  endpoint: T,
  arg: Parameters<typeof api.endpoints[T]["initiate"]>[0],
  api: any = loanNoteApi,
  options: PrefetchOptions = {}
) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(api.util.prefetch(endpoint, arg as any, options));
  }, []);
}
