export enum RoutePath {
  Root = "/",
  Showcase = "/",
  ToC = "/terms-of-conditions",
  NotApplicable = "/not-applicable",
  FiduciaryApplicationForm = "/fiduciary-application",
  PersonalDetails = "/personal-details",
  ContactDetails = "/contact-details",
  FiduciaryServicesRequest = "/fiduciary-services-request",
  NotFound = "/not-found",
  Success = "/success",
  CustomerClassification = "/customer-classification",
  NotAvailable = "/not-available",
}
