import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, MobileStepper } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { RoutePath } from "../../enums/RoutePath";
import useEventListener from "../../hooks/useEventListener";
import { useGetSeriesByISINAndApiKeyQuery, useGetStaticContentQuery } from "../../store/services/loanNote";
import { useAppSelector, usePrefetchImmediately } from "../../store/hooks";
import { selectApiKey, selectISIN } from "../../store/status/statusSlice";
import LandingPage from "./LandingPage";
import ContentPage from "./ContentPage";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const Image = styled("img")(() => ({
  display: "inline-block",
  objectFit: "contain",
  msInterpolationMode: "nearest-neighbor",
}));

const steps = [LandingPage, ContentPage];

type Params = {
  pageNumber: string;
};

export default function TermsOfConditions() {
  const params: Params = useParams();
  const pageNumber = parseInt(params.pageNumber);
  const pageIndex = pageNumber - 1;
  const history = useHistory();
  const theme = useTheme();
  const maxSteps = steps.length;
  const ISIN = useAppSelector(selectISIN);
  const apiKey = useAppSelector(selectApiKey);
  const { data: series } = useGetSeriesByISINAndApiKeyQuery(
    ISIN && apiKey ? { ISIN, apiKey } : skipToken
  );
  const { data: staticContent } = useGetStaticContentQuery();

  const ActivePage = steps[pageIndex];

  const handleNext = () => {
    if (pageNumber >= maxSteps) return;
    history.push(`${RoutePath.ToC}/${pageNumber + 1}`, {
      shouldAnimate: false,
    });
  };

  const handleBack = () => {
    if (pageNumber <= 1) return;
    history.push(`${RoutePath.ToC}/${pageNumber - 1}`, {
      shouldAnimate: false,
    });
  };

  useEventListener("keydown", (e) => {
    if (e.key === "ArrowRight") handleNext();
    if (e.key === "ArrowLeft") handleBack();
  });

  if (!steps[pageIndex]) {
    history.replace(RoutePath.NotFound);
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: "100%",
        width: "100%",
        display: "grid",
      }}
    >
      <ActivePage />
      <Image
        src={staticContent?.brand || series?.issuer_logo}
        className="sharpen-image"
        alt="Company logo"
        sx={{
          height: "4.5rem",
          mixBlendMode: "multiply",
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          WebkitUserModify: "none",
          WebkitUserDrag: "none",
          userDrag: "none",
          zIndex: 100,
          position: "fixed",
          left: 0,
          bottom: 0,
          ml: 3,
          mb: 3,
        }}
      />
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={pageIndex}
        sx={{
          padding: 0,
          whiteSpace: "nowrap",
          borderRadius: 1,
          boxShadow: " 0 0 0.3rem gray",
          height: "fit-content",
          zIndex: 100,
          position: "fixed",
          right: 0,
          bottom: 0,
          mr: 3,
          mb: 3,
        }}
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={pageIndex === 0}
            sx={{
              mr: 1,
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={pageIndex === maxSteps - 1}
            sx={{
              ml: 1,
            }}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
      />
    </Box>
  );
}
