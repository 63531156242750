import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Image } from "./TermsOfConditions";
import { useAppSelector } from "../../store/hooks";
import { selectApiKey, selectISIN } from "../../store/status/statusSlice";
import { useGetSeriesByISINAndApiKeyQuery, useGetStaticContentQuery } from "../../store/services/loanNote";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  NAME_OF_ISSUER,
  NAME_OF_SECURITY,
} from "../../components/forms/fiduciary-services-request-form/fields";

export default function LandingPage() {
  const ISIN = useAppSelector(selectISIN);
  const apiKey = useAppSelector(selectApiKey);
  const { data: series } = useGetSeriesByISINAndApiKeyQuery(
    ISIN && apiKey ? { ISIN, apiKey } : skipToken
  );
  const { data: staticContent } = useGetStaticContentQuery();

  return (
    <Container
      maxWidth="sm"
      component="main"
      sx={{
        minHeight: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          marginTop: "-15%",
        }}
      >
        <Image
          src={ staticContent?.brand || series?.issuer_logo}
          className="sharpen-image"
          alt="Company logo"
          sx={{
            height: "9rem",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Typography
          variant="h4"
          component="h1"
          mt={7}
          textAlign="center"
          textTransform="uppercase"
        >
          {series?.[NAME_OF_SECURITY.name]}
        </Typography>
      </Box>
    </Container>
  );
}
