import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DynamicType } from "../../enums/DynamicType";
import { LoanNoteApiPath } from "../../enums/LoanNoteApiPath";
import { Organisation } from "../../types/Organisation";
import { Series } from "../../types/Series";
import { CMSResponse, StaticContent } from "../../types/StaticContent";

export const loanNoteApi = createApi({
  reducerPath: "loanNoteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BONDSTREAM_API,
  }),
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getStaticContent: builder.query<StaticContent, void>({
      query: () => LoanNoteApiPath.CMS,
      transformResponse: (response: CMSResponse) => response.content,
    }),
    getTypes: builder.query<Record<DynamicType, string[]>, void>({
      query: () => LoanNoteApiPath.LoanNoteTypes,
    }),
    getSeriesByISINAndApiKey: builder.query<
      Series,
      { ISIN: string; apiKey: string }
    >({
      query: ({ ISIN, apiKey }) => ({
        url: `${LoanNoteApiPath.Series}/${ISIN}`,
        headers: { authorization: `Bearer ${apiKey}` },
      }),
    }),
    getNomineeSeriesByApiKey: builder.query<Series[], string>({
      query: (apiKey) => ({
        url: `${LoanNoteApiPath.Series}`,
        headers: { authorization: `Bearer ${apiKey}` },
      }),
    }),
    sendForm: builder.mutation({
      query: ({ body }) => ({
        url: LoanNoteApiPath.LoanNote,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          response.ok && !result.hasOwnProperty("error"),
      }),
    }),
  }),
});

export const {
  useGetStaticContentQuery,
  useGetTypesQuery,
  useGetSeriesByISINAndApiKeyQuery,
  useGetNomineeSeriesByApiKeyQuery,
  useSendFormMutation,
  usePrefetch,
  endpoints: {
    getStaticContent: {
      useQuerySubscription: useGetStaticContentQuerySubscription,
    },
    getTypes: { useQuerySubscription: useGetTypesQuerySubscription },
  },
} = loanNoteApi;
