import { Fade, Slide } from "@mui/material";
import React from "react";
import { AnimationType } from "./enums/AnimationType";

export default function Animator<T>(
  Component: React.ComponentType<T>,
  animationType: string | AnimationType = AnimationType.Fade
): React.ComponentType<T> {
  const AnimationComponent =
    animationType === AnimationType.Fade ? Fade : Slide;
  return (props) => (
    <AnimationComponent in={true} timeout={400}>
      <div className="animation-wrapper">
        <Component {...props} />
      </div>
    </AnimationComponent>
  );
}
